import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "form-label-wrapper form-label-width" }
const _hoisted_3 = ["for"]
const _hoisted_4 = {
  key: 0,
  class: "form-label__asterisk"
}
const _hoisted_5 = { class: "form-label__text" }
const _hoisted_6 = { class: "form-field" }
const _hoisted_7 = { class: "form-field-wrapper" }
const _hoisted_8 = { class: "form-row-bottom" }
const _hoisted_9 = { class: "form-field" }
const _hoisted_10 = { class: "form-message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["form-row mb-2", {
      'bg-light-info': _ctx.highlighted,
      'form-row_colored': _ctx.highlighted,
    }]),
    id: _ctx.id
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["form-row-top", _ctx.toTop ? 'form-row-top_to-top' : ''])
    }, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.is !== 'base-single-checkbox')
          ? (_openBlock(), _createElementBlock("label", {
              key: 0,
              class: "mr-2 form-label",
              for: _ctx.id
            }, [
              (_ctx.required)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, " * "))
                : _createCommentVNode("", true),
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.label), 1)
            ], 8, _hoisted_3))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ])
    ], 2),
    _withDirectives(_createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", {
        class: "form-label-wrapper form-label-width",
        style: _normalizeStyle({ width: _ctx.labelWidth + 'px', 'min-width': _ctx.labelWidth + 'px' })
      }, null, 4),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.errorMessage), 1)
      ])
    ], 512), [
      [_vShow, _ctx.errorMessage && _ctx.errorMessage.length]
    ])
  ], 10, _hoisted_1))
}