import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "px-10 py-4 rounded border text-center mb-4"
}
const _hoisted_2 = {
  key: 1,
  class: "px-10 py-4 rounded border text-center my-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.paginationShowLocal)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_el_pagination, {
            background: "",
            small: "small",
            "page-size": 10,
            "pager-count": 4,
            total: _ctx.itemsTotal,
            "current-page": _ctx.currentPageLocal,
            layout: "prev, pager, next",
            onCurrentChange: _ctx.paginationClick
          }, null, 8, ["total", "current-page", "onCurrentChange"])
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.paginationShowLocal)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_el_pagination, {
            background: "",
            small: "small",
            "page-size": 10,
            "pager-count": 4,
            total: _ctx.itemsTotal,
            "current-page": _ctx.currentPageLocal,
            layout: "prev, pager, next",
            onCurrentChange: _ctx.paginationClick
          }, null, 8, ["total", "current-page", "onCurrentChange"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}