
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'base-form-field',
  components: {},
  props: {
    label: {
      type: String,
      default: 'Имя поля',
      required: false,
    },
    labelWidth: {
      type: Number,
      default: 200,
      required: false,
    },
    required: {
      type: Boolean,
      default: false,
      required: false,
    },
    errorMessage: {
      type: String,
      default: '',
      required: false,
    },
    toTop: {
      type: Boolean,
      default: false,
      required: false,
    },
    highlighted: {
      type: Boolean,
      default: false,
      required: false,
    },
    id: {
      type: String,
      default: 'id-default',
      required: true,
    },
    is: {
      type: String,
      default: '',
      required: false,
    },
  },
})
