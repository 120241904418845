import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createBlock(_component_el_input, {
    class: _normalizeClass(["my-input w-100", {
      'el-input--invalid': !_ctx.isValid
    }]),
    id: _ctx.id,
    maxlength: _ctx.maxLength,
    minlength: _ctx.minLength,
    clearable: _ctx.clearable,
    placeholder: _ctx.placeholder,
    "show-word-limit": _ctx.showWordLimit,
    modelValue: _ctx.inputModelValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputModelValue) = $event))
  }, null, 8, ["class", "id", "maxlength", "minlength", "clearable", "placeholder", "show-word-limit", "modelValue"]))
}