
import {
  defineComponent,
} from 'vue'

export default defineComponent({
  name: 'base-paragprah',
  props: {
    modelValue: {
      type: String,
      required: false,
    },
    id: {
      type: String,
      default: 'id-default',
      required: true,
    },
  },
})
