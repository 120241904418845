
import {
  ref,
  computed,
  onMounted,
  defineComponent
} from 'vue';
import { ElMessage } from 'element-plus';
import { validationRules as rules } from '@/core/helpers/validation/validationRules';
import * as Yup from "yup";

export default defineComponent({
  name: 'base-upload-2',
  props: {
    modelValue: {
      default: () => {
        return []
      },
      type: Array,
      required: false
    },
    id: {
      type: String,
      default: 'id-default',
      required: true,
    },
    maxFileSize: {
      type: Number,
      default: 1,
      required: false,
    },
    schema: {
      type: Object,
      default: null,
      required: false,
    },
    multiple: {
      type: Boolean,
      default: false,
      required: false,
    },
    isValid: {
      type: Boolean,
      default: false,
      required: false,
    },
    errorMessage: {
      type: String,
      default: null,
      required: false,
    },
  },
  setup(props, context) {
    const photosList = ref([]);

    const checkFileSize = size => {
      const isLt10Mb = size <= rules.upload.common.fileSize.max.value
      if (isLt10Mb) {
        return true
      } else {
        ElMessage.error(`Размер файла не должен быть более ${rules.upload.common.fileSize.max.label}`)
        return false
      }
    }

    const typeStatus = type => {
      const typeStatus = type === 'image/png' || type === 'image/jpeg'
      if (typeStatus) {
        return true
      } else {
        ElMessage.error('Разрешены только PNG или JPG форматы')
        return false
      }
    }

    const validateFile = file => {
      let result = false
      const fileSize = file.size
      const fileType = file.raw.type

      if (checkFileSize(fileSize) && typeStatus(fileType)) {
        result = true
      } else {
        result = false
      }
      return result
    }

    const errorMessage = ref(props.errorMessage);
    const fieldSchema = Yup.object().shape(props.schema);

    const validateField = async (value) => {
      // console.log('> validateField >> value', value);
      let inputValue = value;
      await fieldSchema.validate(
        { field: value },
        { abortEarly: false },
      )
        .then(value => {
          // console.log('> validateField >> success', value.field);
          inputValue = value.field;
          errorMessage.value = null;
        })
          .catch(err => {
            if (err && err.errors) {
              console.log('> validateField >> error', err.errors);
              errorMessage.value = err.errors[err.errors.length - 1];
            }
          });

      context.emit('update:isValid', errorMessage.value && errorMessage.value.length ? false : true);
      context.emit('update:errorMessage', errorMessage);

      if (inputValue) {
        return inputValue;
      }
      return [];
    }

    let uploadFinish = true;
    let finalArray = [];

    const handleUploadFull = async (array) => {
      finalArray = [];
      const arrayResult = Array.from(photosList.value);
      const arrayCopy = Array.from(array).concat();
      // console.log('> handleUploadFull >> arrayCopy', arrayCopy);
      // console.log('> handleUploadFull >> arrayResult 1', arrayResult);

      if (arrayCopy && arrayCopy.length) {
        arrayCopy.forEach(item => {
          if (item['raw']) {
            if (validateFile(item)) {
              item['new'] = true;
              item['id'] = item['uid'];
              arrayResult.push(item);
              // console.log('> push >> arrayResult', arrayResult);
            }
          } else {
            console.log('> push >> else', arrayResult);
            arrayResult.push(item);
          }
        });
      }

      photosList.value = await validateField(arrayResult);
      // console.log('> handleUploadFull >> arrayResult 2', arrayResult);
      context.emit('update:modelValue', await validateField(arrayResult));
    };

    /* SETTIMEOUT */
    let setTimeoutId;
    const stopTimeOut = id => clearTimeout(id);
    const startTimeOut = () => {
      setTimeoutId = setTimeout(async () => {
        // console.log('> setTimeout >> finalArray', finalArray);
        uploadFinish ? await handleUploadFull(finalArray) : '';
      }, 100);
    };

    const uploadPhoto = (file) => {
      finalArray.push(file);
      stopTimeOut(setTimeoutId);
      // console.log('> uploadPhoto >> file', file);
      uploadFinish = false;
      startTimeOut();
      uploadFinish = true;
    };

    /* SETTIMEOUT */


    /**
     * Handle removing files.
     * @param {object} File object
     */
    const removePhoto = async file => {
      photosList.value = photosList.value.filter(
        item => item.id !== file.id
      );
      // if (photosList.value && !photosList.value.length) {
      //   photosList.value = null
      // }
      // console.log('> photosList.value >>', photosList.value);
      context.emit('update:modelValue', await validateField(photosList.value));
    }

    const dialogImageUrl = ref('');
    const dialogVisible = ref(false);

    const previewPhoto = (uploadFile) => {
      dialogImageUrl.value = uploadFile.url!;
      dialogVisible.value = true;
    };

    /**
     * Watching for the Photos list property value changes,
     * and making a copy of the props photo list.
     */
    // watch(
    //   () => props.modelValue,
    //   newValue => {
    //     photosList.value = handleUploadFull(newValue)
    //   }
    // )

    const inputModelValue = computed({
      get() {
        // console.log('> ph photosList.value >>', photosList.value);
        return photosList.value;
      },
      async set(value) {
        // console.log('> set inputModelValue >>', value);
        photosList.value = await validateField(value);
        return context.emit('update:modelValue', photosList.value);
      }
    });

    onMounted(() => {
      if (props.modelValue) {
        photosList.value = props.modelValue;
      }
    });

    return {
      rules,
      photosList,
      dialogVisible,
      dialogImageUrl,
      previewPhoto,
      inputModelValue,
      removePhoto,
      uploadPhoto,
    }
  },
});
