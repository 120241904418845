import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_cascader = _resolveComponent("el-cascader")!

  return (_openBlock(), _createBlock(_component_el_cascader, {
    id: _ctx.id,
    size: _ctx.size,
    class: "w-100",
    clearable: _ctx.clearable,
    filterable: _ctx.filterable,
    props: {
      value: 'id',
      emitPath: false,
      multiple: true,
      checkStrictly: true,
    },
    options: _ctx.options,
    placeholder: _ctx.placeholder,
    modelValue: _ctx.inputModelValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputModelValue) = $event))
  }, null, 8, ["id", "size", "clearable", "filterable", "options", "placeholder", "modelValue"]))
}