
import {
  ref,
  computed,
  defineComponent,
} from 'vue'
// import { trimOnlyNumbers } from '@/core/helpers/isNumber';
// import { errorMessagesSet } from '@/core/helpers/errorMessagesSet';
import * as Yup from "yup";

export default defineComponent({
  name: 'base-input-email',
  props: {
    modelValue: {
      type: String,
    },
    id: {
      type: String,
      default: 'id-default',
      required: true,
    },
    showWordLimit: {
      type: Boolean,
      default: true,
      required: false,
    },
    minLength: {
      type: Number,
      required: false,
    },
    maxLength: {
      type: Number,
      required: false,
    },
    placeholder: {
      type: String,
      default: 'Введите значение',
      required: false,
    },
    clearable: {
      type: Boolean,
      default: true,
      required: false,
    },
    size: {
      type: String,
      default: 'large',
      required: false,
    },
    schema: {
      type: Object,
      default: null,
      required: false,
    },
    isValid: {
      type: Boolean,
      default: false,
      required: false,
    },
    errorMessage: {
      type: String,
      default: null,
      required: false,
    },
  },
  setup(props, context) {
    const errorMessage = ref(props.errorMessage);

    const fieldSchema = Yup.object().shape(props.schema);

    const inputModelValue = computed({
      get() {
        return props.modelValue;
      },
      async set(value) {
        // console.log('> txt value >>', value);
        let inputValue = value;

        await fieldSchema.validate(
          { field: value },
          { abortEarly: false },
        )
          .then(value => {
            inputValue = value.field;
            errorMessage.value = null;
          })
            .catch(err => {
              errorMessage.value = err.errors[err.errors.length - 1];
            });

        context.emit('update:isValid', errorMessage.value && errorMessage.value.length ? false : true);
        context.emit('update:errorMessage', errorMessage);

        return context.emit('update:modelValue', inputValue);
      }
    });

    return {
      inputModelValue,
    }
  },
})
