
// Vue
import store from '@/store/index';
import { Actions, Mutations } from '@/store/enums/store.enums';
import { ref, computed, onMounted, defineComponent } from 'vue';
// Services
import ApiService from '@/core/services/ApiService';
import DadataService from '@/core/services/DadataService';
// Custom components
import BasePre from '@/core/components/base/BasePre.vue';
import BaseFormField from '@/core/components/base/BaseFormField.vue';
// Helpers
import isEmptyObject from '@/core/helpers/isEmptyObject';
import { validationRules } from '@/core/helpers/validation/validationRules';
import { ElementAnimateUtil } from '@/assets/ts/_utils/ElementAnimateUtil';

import BaseParagraph from '@/core/components/base/BaseParagraph.vue';
import BaseInputText from '@/core/components/base/BaseInputText.vue';
import BaseSelect from '@/core/components/base/BaseSelect.vue';
import BaseSingleCheckbox from '@/core/components/base/BaseSingleCheckbox.vue';
import BaseUpload2 from '@/core/components/base/BaseUpload2.vue';
import BaseInputEmail from '@/core/components/base/BaseInputEmail.vue';
import BaseInputPhone from '@/core/components/base/BaseInputPhone.vue';
import BaseCascader from '@/core/components/base/BaseCascader.vue';
import BaseAutocomplete from '@/core/components/base/BaseAutocomplete.vue';
import BaseTable from '@/core/components/base/BaseTable.vue';
import { errorMessagesSet } from '@/core/helpers/errorMessagesSet';

import * as Yup from "yup";
import { ElMessage } from 'element-plus'
import { onlyNumberPattern } from '@/core/helpers/isNumber';


export default defineComponent({
  name: 'FormPage',
  components: {
    BasePre,
    BaseFormField,
    BaseSelect,
    BaseParagraph,
    BaseInputText,
    BaseSingleCheckbox,
    BaseUpload2,
    BaseInputEmail,
    BaseInputPhone,
    BaseCascader,
    BaseAutocomplete,
    BaseTable,
  },
  props: {
    data: {
      default: () => {
        return {}
      },
      type: Object,
      required: true,
    },
    edit: {
      default: true,
      type: Boolean,
      required: true,
    },
  },
  setup(props, context) {
    const propData = Object.assign({}, props.data);

    const isTableLoading = ref(false);

    const activeTabName = ref('user');

    const tabsSetOrigin = [
      {
        label: 'Пользователь',
        slug: 'user',
      },
      {
        label: 'Сменить пароль',
        slug: 'password-change',
      },
      {
        label: 'Настройки уведомлений',
        slug: 'notification-settings',
      },
      {
        label: 'Рассылки',
        slug: 'mailing',
      },
      {
        label: 'Лог авторизации',
        slug: 'auth-log',
      },
    ];

    /**
     * Convert tabs for the Create user page.
     * @returns {array}.
     */
    const tabsSetConverted = computed(() => {
      let tabsArray = [];
      if (props.edit) {
        tabsArray = tabsSetOrigin;
      } else {
        tabsSetOrigin.forEach(item => {
          if (item.slug !== 'auth-log') {
            if (item.slug === 'password-change') {
              item.label = 'Задать пароль';
            }
            tabsArray.push(item);
          }
        });
      }
      return tabsArray;
    });

    const statusesSet = ref({
      confirmed: {
        title: 'Профиль одобрен модератором',
        icon: 'success',
      },
      'not_verified': {
        title: 'Ожидает проверки',
        icon: 'warning',
      },
      rejected: {
        title: 'Профиль отклонён',
        icon: 'error',
      },
      unknown: {
        title: 'Статус профиля неизвестен',
        icon: 'info',
      },
    });

    /*********************
     * FORM START
     *********************/
    const placeholder = 'Введите значение';

    /**
     * The entry data object.
     */
    const entryData = ref({
      'account_pro': null,
      active: null,
      email: null,
      id: null,
      'last_login': null,
      'last_name': null,
      'last_update': null,
      name: null,
      phone: null,
      'phone_confirmed': null,
      photo: null,
      'registered_at': null,
      'second_name': null,
      status: null,
      subscriptions: null,
      'user_type': null,
    })

    const authLogsData = ref({});

    const leaveOnlyLettersRule = Yup
      .string()
        .nullable()
          .transform(currentValue => {
            if (currentValue && currentValue.length) {
              return currentValue.replace(onlyNumberPattern, '');
            }
            return currentValue;
          });

    const theNameFormRule = Yup
      .string()
        .min(2, 'Минимум 2 символа')
          .max(30, 'Максимум 30 символов')
            // .test("maxLenght", "Max 30", val => `${val}`.length < 28)
            .required(errorMessagesSet.required);

    const theLastNameFormRule = Yup
      .string()
        .nullable()
          .transform((v, o) => (o === '' ? null : v))
            .min(2, 'Минимум 2 символа')
              .max(50, 'Максимум 50 символов');

    const theSecondNameFormRule = theLastNameFormRule;

    const stringRequiredRule = Yup
      .string()
        .nullable()
          .required(errorMessagesSet.required);

    const photoRule = Yup
      .array()
        .nullable()
          .default(null)
            .transform(currentValue => {
              // console.log('> currentVal photoRule', currentValue);
              if (currentValue && currentValue.length > 1) {
                console.log('> if photoRule');
                const getfirst = currentValue[0];
                ElMessage.warning(`Максимум 1 фото`);
                return [getfirst];
              }
              // console.log('> else photoRule');
              return currentValue;
            })
              .max(1, 'Максимум 1 фото');

    const emailRule = Yup
      .string()
        .nullable()
          .email('Введите валидный email')
            .required(errorMessagesSet.required);

    const phoneRule = Yup
      .string()
        .nullable()
          .min(11, 'Минимум 11 чисел')
            .max(18, 'Максимум 18 чисел')
              .required(errorMessagesSet.required);

    const arrayMax3Rule = Yup
      .array()
        .nullable()
          .default(null)
            .transform(currentValue => {
              // console.log('> currentVal', currentValue);
              if (currentValue && currentValue.length > 3) {
                // console.log('> if');
                const getFirstThree = [currentValue[0], currentValue[1], currentValue[2]];
                // new Yup.ValidationError('Максимум 3 рубрики');
                ElMessage.warning(`Максимум 3 рубрики`);
                return getFirstThree;
              }
              // console.log('> else arrayMax3Rule');
              return currentValue;
            })
              .max(3, 'Максимум 3 рубрики');

    const passwordRuleEdit = Yup
      .string()
        .nullable()
          .default(null)
            .min(6, 'Минимум 6 символов');

    const passwordRuleCreate = Yup
      .string()
        .nullable()
          .min(6, 'Минимум 6 символов')
            .required(errorMessagesSet.required);

    const passwordConfirmRuleEdit = Yup
      .string()
        .nullable()
          .default(null)
            .min(6, 'Минимум 6 символов')
              .oneOf([Yup.ref('password'), null], 'Пароли должны совпадать');

    const passwordConfirmRuleCreate = Yup
      .string()
        .nullable()
          .min(6, 'Минимум 6 символов')
            .oneOf([Yup.ref('password'), null], 'Пароли должны совпадать')
              .required(errorMessagesSet.required);


    const schema = Yup.object().shape({
      'user_type': stringRequiredRule,
      'status': stringRequiredRule,
      'photo': photoRule,
      'name': theNameFormRule.concat(leaveOnlyLettersRule),
      'last_name': theLastNameFormRule.concat(leaveOnlyLettersRule),
      'second_name': theSecondNameFormRule.concat(leaveOnlyLettersRule),
      'email': emailRule,
      'phone': phoneRule,
      'offer_rubrics': arrayMax3Rule,
      'demand_rubrics': arrayMax3Rule,
      'password': props.edit ? passwordRuleEdit : passwordRuleCreate,
      'password_confirm': props.edit ? passwordConfirmRuleEdit : passwordConfirmRuleCreate,
      'demand_to_email_rubrics': arrayMax3Rule,
    });

    /**
     * Request for address suggestions.
     */
    const addressSuggestions = async (queryString, cb) => {
      DadataService.setHeader();

      const query = {
        query: queryString,
        'from_bound': { value: 'city' },
        'to_bound': { value: 'settlement' },
        locations: [ { country: '*' } ],
      }

      const resultSuggestions = await DadataService.post('/suggest/address', { ...query })
        .then(response => response.data.suggestions)
          .catch(error => []);

        cb(resultSuggestions);
    };

    const entryDataFull = ref({
      'registered_at': {
        tab: 'user',
        as: 'base-paragraph',
        slug: 'registered_at',
        value: null,
        label: 'Дата регистрации',
        highlighted: false,
        toTop: false,
        available: false,
      },
      'last_update': {
        tab: 'user',
        as: 'base-paragraph',
        slug: 'last_update',
        value: null,
        label: 'Дата обновления',
        highlighted: false,
        toTop: false,
        available: false,
      },
      'last_login': {
        tab: 'user',
        as: 'base-paragraph',
        slug: 'last_login',
        value: null,
        label: 'Последняя авторизация',
        highlighted: false,
        toTop: false,
        available: false,
      },
      active: {
        tab: 'user',
        as: 'base-single-checkbox',
        slug: 'active',
        value: false,
        label: 'Активен',
        available: true,
        highlighted: false,
        toTop: false,
      },
      'user_type': {
        tab: 'user',
        as: 'base-select',
        slug: 'user_type',
        value: null,
        label: 'Тип',
        placeholder: placeholder,
        available: true,
        maxLength: 100,
        highlighted: false,
        toTop: false,
        options: [
          {
            value: 'buyer',
            label: 'Покупатель',
          },
          {
            value: 'seller',
            label: 'Продавец',
          },
        ],
        isValid: null,
        required: true,
        errorMessage: null,
        schema: { field: stringRequiredRule },
      },
      status: {
        tab: 'user',
        as: 'base-select',
        slug: 'status',
        value: null,
        label: 'Статус',
        placeholder: placeholder,
        available: true,
        maxLength: 100,
        highlighted: false,
        toTop: false,
        options: [
          {
            value: 'not_verified',
            label: 'Не проверен',
          },
          {
            value: 'confirmed',
            label: 'Проверен',
          },
          {
            value: 'rejected',
            label: 'Отклонён',
          },
        ],
        isValid: null,
        required: true,
        errorMessage: null,
        schema: { field: stringRequiredRule },
      },
      photo: {
        tab: 'user',
        as: 'base-upload-2',
        label: 'Фото',
        slug: 'photo',
        value: [],
        available: true,
        highlighted: false,
        toTop: true,
        isValid: null,
        required: false,
        errorMessage: null,
        schema: { field: photoRule },
      },
      name: {
        tab: 'user',
        as: 'base-input-text',
        slug: 'name',
        value: null,
        label: 'Имя',
        placeholder: placeholder,
        available: true,
        minLength: 2,
        maxLength: 30,
        maxNumber: null,
        highlighted: false,
        toTop: false,
        isValid: null,
        required: true,
        errorMessage: null,
        showWordLimit: true,
        schema: { field: leaveOnlyLettersRule },
      },
      'last_name': {
        tab: 'user',
        as: 'base-input-text',
        slug: 'last_name',
        value: null,
        label: 'Фамилия',
        placeholder: placeholder,
        available: true,
        maxLength: 50,
        maxNumber: null,
        highlighted: false,
        toTop: false,
        required: false,
        isValid: null,
        errorMessage: null,
        showWordLimit: true,
        schema: { field: leaveOnlyLettersRule },
      },
      'second_name': {
        tab: 'user',
        as: 'base-input-text',
        slug: 'second_name',
        value: null,
        label: 'Отчество',
        placeholder: placeholder,
        available: true,
        maxLength: 50,
        maxNumber: null,
        highlighted: false,
        toTop: false,
        isValid: null,
        required: false,
        errorMessage: null,
        showWordLimit: true,
        schema: { field: leaveOnlyLettersRule },
      },
      email: {
        tab: 'user',
        as: 'base-input-email',
        slug: 'email',
        value: null,
        label: 'E-mail',
        placeholder: placeholder,
        available: true,
        minLength: 3,
        maxLength: 100,
        highlighted: false,
        toTop: false,
        isValid: null,
        required: true,
        errorMessage: null,
        showWordLimit: true,
        schema: { field: emailRule },
      },
      phone: {
        tab: 'user',
        as: 'base-input-phone',
        slug: 'phone',
        value: null,
        label: 'Телефон',
        placeholder: placeholder,
        showWordLimit: true,
        available: true,
        maxLength: 23,
        isValid: null,
        required: true,
        errorMessage: null,
        clearable: true,
        highlighted: false,
        toTop: false,
      },
      'offer_rubrics': {
        tab: 'user',
        as: 'base-cascader',
        slug: 'offer_rubrics',
        value: null,
        label: 'Специализация продажа',
        placeholder: placeholder,
        validRules: [],
        options: null,
        available: true,
        maxLength: 20,
        filterable: true,
        clearable: true,
        highlighted: false,
        toTop: false,
        isValid: null,
        required: false,
        errorMessage: null,
        showWordLimit: true,
        schema: { field: arrayMax3Rule },
      },
      'demand_rubrics': {
        tab: 'user',
        as: 'base-cascader',
        slug: 'demand_rubrics',
        value: null,
        label: 'Специализация спрос',
        placeholder: placeholder,
        options: null,
        available: true,
        filterable: true,
        clearable: true,
        highlighted: false,
        toTop: false,
        isValid: null,
        required: false,
        errorMessage: null,
        showWordLimit: false,
        schema: { field: arrayMax3Rule },
      },
      'address': {
        tab: 'user',
        as: 'base-autocomplete',
        slug: 'address',
        value: null,
        jsonValue: null,
        label: 'Местоположение',
        placeholder: placeholder,
        required: false,
        options: null,
        available: true,
        maxLength: 20,
        filterable: true,
        clearable: true,
        highlighted: false,
        suggestions: addressSuggestions,
        showWordLimit: true,
        toTop: false,
        isValid: null,
        errorMessage: null,
      },
      'show_advert': {
        tab: 'user',
        as: 'base-single-checkbox',
        slug: 'show_advert',
        value: false,
        label: 'Показывать рекламу',
        available: true,
        highlighted: false,
        toTop: false,
      },
      'show_public': {
        tab: 'user',
        as: 'base-single-checkbox',
        slug: 'show_public',
        value: false,
        label: 'Показывать в каталоге участников ТС',
        available: true,
        highlighted: false,
        toTop: false,
      },
      'show_email': {
        tab: 'user',
        as: 'base-single-checkbox',
        slug: 'show_email',
        value: false,
        label: 'Отображать eMail в контактах',
        available: true,
        highlighted: false,
        toTop: false,
      },
      'email_bounced': {
        tab: 'user',
        as: 'base-single-checkbox',
        slug: 'email_bounced',
        value: false,
        label: 'Проблемы с email (bounced)',
        available: true,
        highlighted: false,
        toTop: false,
      },
      'email_complained': {
        tab: 'user',
        as: 'base-single-checkbox',
        slug: 'email_complained',
        value: false,
        label: 'Проблемы с email (complained)',
        available: true,
        highlighted: false,
        toTop: false,
      },
      'not_check_bounced': {
        tab: 'user',
        as: 'base-single-checkbox',
        slug: 'not_check_bounced',
        value: false,
        label: 'Не проверять проблемы с email (bounced или complained)',
        available: true,
        highlighted: false,
        toTop: false,
      },
      password: {
        tab: 'password-change',
        as: 'base-input-text',
        type: 'password',
        slug: 'password',
        value: null,
        label: 'Пароль',
        placeholder: placeholder,
        available: true,
        minLength: 2,
        maxLength: 16,
        maxNumber: null,
        highlighted: false,
        toTop: false,
        isValid: null,
        required: false,
        errorMessage: null,
        showWordLimit: true,
        schema: null,
      },
      'password_confirm': {
        tab: 'password-change',
        as: 'base-input-text',
        type: 'password',
        slug: 'password_confirm',
        value: null,
        label: 'Повторите пароль',
        placeholder: placeholder,
        available: true,
        minLength: 2,
        maxLength: 16,
        maxNumber: null,
        highlighted: false,
        toTop: false,
        isValid: null,
        required: false,
        errorMessage: null,
        showWordLimit: true,
        schema: null,
      },
      'demand_to_email': {
        tab: 'notification-settings',
        as: 'base-single-checkbox',
        slug: 'demand_to_email',
        value: false,
        label: 'eMail',
        placeholder: placeholder,
        showWordLimit: true,
        validRules: [],
        available: true,
        maxLength: 100,
        isValid: null,
        highlighted: false,
        toTop: false,
      },
      'demand_to_email_rubrics': {
        tab: 'notification-settings',
        as: 'base-cascader',
        slug: 'demand_to_email_rubrics',
        value: null,
        jsonValue: null,
        label: 'Получать уведомления',
        placeholder: placeholder,
        showWordLimit: true,
        options: null,
        available: true,
        filterable: true,
        clearable: true,
        highlighted: false,
        toTop: false,
        isValid: null,
        errorMessage: null,
        schema: { field: arrayMax3Rule },
      },
      'order-offer': {
        tab: 'notification-settings',
        as: 'base-single-checkbox',
        slug: 'order-offer',
        value: false,
        label: 'Новые отклики (заказы/предложения) на мои объявления',
        available: true,
        highlighted: false,
        toTop: false,
      },
      'comments-order-offer': {
        tab: 'notification-settings',
        as: 'base-single-checkbox',
        slug: 'comments-order-offer',
        value: false,
        label: 'Новые комментарии к заказам/предложениям по моим объявлениям',
        available: true,
        highlighted: false,
        toTop: false,
      },
      'doska-expired': {
        tab: 'notification-settings',
        as: 'base-single-checkbox',
        slug: 'doska-expired',
        value: false,
        label: 'Напоминание об истечении срока действия моих объявлений',
        available: true,
        highlighted: false,
        toTop: false,
      },
      'new-message-chat': {
        tab: 'notification-settings',
        as: 'base-single-checkbox',
        slug: 'new-message-chat',
        value: false,
        label: 'Новые личные сообщения (чат)',
        available: true,
        highlighted: false,
        toTop: false,
      },
    });

    const entryDataArray = ref([]);

    /**
     * Scroll the window to the invalid field.
     */
    const scrollToInvalid = element => {
      const refsEl = document.getElementById(element);
      if (refsEl) {
        ElementAnimateUtil.scrollTo(refsEl, 100, 500);
      }
    };


    const schemaValidate = async (yupObject) => {
      return await schema.validate(
        yupObject,
        { abortEarly: false },
      )
        .then((value) => {
          // console.log('> schemaValidate >> success', value);
          return []
        })
          .catch(error => {
            // console.log('> schemaValidate >> error.inner', error.inner);
            return error.inner
          });
    };

    const convertErrorMessages = array => {
      let errorsJson = {};
      if (array && array.length) {
        array.forEach(
          item => {
            errorsJson[item.path] = {
              slug: item.path,
              message: item.message,
            }
          }
        );
      } else {
        errorsJson = null
      }
      return errorsJson;
    };


    const validateForm = async () => {
      const yupObject = {};
      entryDataArray.value.forEach(item => {
        yupObject[item.slug] = item.value;
      });
      const validationResult = await schemaValidate(yupObject);
      const errorMessages = convertErrorMessages(validationResult);

      entryDataArray.value = entryDataArray.value.map(
        item => {
          if (errorMessages && errorMessages[item.slug]) {
            item.isValid = false;
            item.errorMessage = errorMessages[item.slug].message;
            errorMessages[item.slug].tab = item.tab;
            // console.log('> item', item);
          } else {
            item.isValid = true;
            item.errorMessage = null;
          }
          return item;
        }
      );

      if (errorMessages && !isEmptyObject(errorMessages)) {
        activeTabName.value = errorMessages[Object.keys(errorMessages)[0]].tab;
        setTimeout(() => {
          scrollToInvalid(errorMessages[Object.keys(errorMessages)[0]].slug);
        }, 1000);
        return false;
      }
      return true;
    };

    /**
     * Convert the object data to the array data for editng.
     * @param {object} Entry data object.
     * @returns {array} Entry data array.
     */
    const convertObjectToArray = object => {
      const array = [];
      for (const key in object) {
        array.push(entryDataFull.value[key]);
      }
      return array;
    };

    /**
     * Update the fields value in the start object.
     * @param {object} Entry data object.
     */
    const updateValueInObject = data => {
      for (const key in data) {
        entryData.value[key] = data[key];
      }
    };

    /**
     * Update the fields value in the full object.
     * @param {object} Entry data object.
     */
    const updateValueInFullObject = data => {
      // console.log('> data', data);
      for (const key in data) {
        // console.log('> key', key)
        if (entryDataFull.value[key]) {
          if (key === 'photo' && data['photo'] !== null) {
            const photoObject = { ...data[key] }
            photoObject.name = photoObject.original_name
            photoObject.url = photoObject.path
            entryDataFull.value[key].value = [photoObject]
          } else if (key === 'address' && data['address'] !== null) {
            entryDataFull.value[key].value = data['address'].value
            entryDataFull.value[key].jsonValue = data['address']
          } else if (key === 'offer_rubrics' && data['offer_rubrics'] !== null) {
            entryDataFull.value['offer_rubrics'].value = []
            data['offer_rubrics'].forEach(item => {
              entryDataFull.value['offer_rubrics'].value.push(item.id)
            })
          } else if (key === 'demand_rubrics' && data['demand_rubrics'] !== null) {
            entryDataFull.value['demand_rubrics'].value = []
            data['demand_rubrics'].forEach(item => {
              entryDataFull.value['demand_rubrics'].value.push(item.id)
            })
          } else if (key === 'demand_to_email_rubrics' && data['demand_to_email_rubrics'] !== null) {
            entryDataFull.value['demand_to_email_rubrics'].value = []
            data['demand_to_email_rubrics'].forEach(item => {
              entryDataFull.value['demand_to_email_rubrics'].value.push(item.id)
            })
          } else {
            entryDataFull.value[key].value = data[key]
          }
        } else if (key === 'alert_settings') {
          for (const key in data['alert_settings']) {
            if (entryDataFull.value[key]) {
              entryDataFull.value[key].value = data['alert_settings'][key]
            }
          }
        } else if (key === 'subscriptions') {
          data['subscriptions'].forEach(
            item => {
              // console.log('> asd', entryDataFull.value)
              entryDataFull.value[`mailing-${item}`].value = true
            }
          )
        }
      }
    }

    /**
     * Convert the data for editing.
     * @param {object} Entry data object.
     */
    const convertDataForEdit = data => {
      if (props.edit) {
        updateValueInObject(data);
        updateValueInFullObject(data);
      }
      entryDataArray.value = convertObjectToArray(entryDataFull.value);
    }

    const convertedData = ref({
      'alert_settings': {},
      subscriptions: [],
    });

    /**
     * Convert back the array data to the object data for sending.
     * @param {object} Entry data object.
     */
    const convertDataForSending = dataArray => {
      dataArray.forEach(
        item => {
          if (item.tab === 'user' ) {
            if (item.value !== null && item.slug !== 'registered_at') {
              convertedData.value[item.slug] = item.value;
              if (item.slug === 'address') {
                if (item.jsonValue && item.jsonValue.id) {
                  convertedData.value['address_id'] = item.jsonValue.id;
                } else {
                  convertedData.value['address_json'] = item.jsonValue;
                }
              }
            }
          } else if (item.tab === 'password-change') {
            if (item.value) {
              convertedData.value[item.slug] = item.value;
            } else {
              delete convertedData.value['pasword']
              delete convertedData.value['password_confirm']
            }
          } else if (item.tab === 'notification-settings') {
            if (
              item.slug === 'order-offer'
              || item.slug === 'doska-expired'
              || item.slug === 'new-message-chat'
              || item.slug === 'comments-order-offer'
            ) {
              convertedData.value['alert_settings'][item.slug] = item.value;
            } else {
              convertedData.value[item.slug] = item.value;
            }
          } else if (item.tab === 'mailing') {
            if (item.value) {
              const susbscriptionNumber = item.slug.replace('mailing-', '');
              convertedData.value.subscriptions.push(susbscriptionNumber);
            }
          }
        }
      )

      // console.log('> convertedData', convertedData);
      return convertedData;
    };

    const saveForm = async () => {
      if (await validateForm()) {
        const data = convertDataForSending(entryDataArray.value);
        context.emit('saveFormEmit', true, data.value);
      }
    };

    /**
     * Getting the entry data
     */
    const getEntryData = data => {
      if (data && !isEmptyObject(data)) {
        convertDataForEdit(data);
      }
    };

    const rubricsInitTree = ref(null);

    /**
     * Makes request for rubrics array.
     * @returns {array} Array of rubrics.
     */
    const rubricsRequest = async () => {
      return await store.dispatch(
        Actions.GET_RUBRICS_LIST,
        { limit: 10 }
      );
    };

    /**
     * Getting rubrics.
     * Making list view.
     * Making tree view.
     * Push the data to the state.
     */
    const getRubrics = async () => {
      let rubrucsList = [];
      rubrucsList = await rubricsRequest();
      store.commit(Mutations.SET_RUBRICS_LIST_MUTATION, rubrucsList);
      rubricsInitTree.value = await store.dispatch(Actions.SET_RUBRICS_TREE, rubrucsList);
      entryDataFull.value['offer_rubrics'].options = Array.from(rubricsInitTree.value);
      entryDataFull.value['demand_rubrics'].options = Array.from(rubricsInitTree.value);
      entryDataFull.value['demand_to_email_rubrics'].options = Array.from(rubricsInitTree.value);
    };

    /**
     * Request for the user's auth logs.
     * @returns {array|null}.
     */
    const getUserAuthLogs = async (currentPage) => {
      // console.log('> currentPage', currentPage);
      isTableLoading.value = true;
      ApiService.setHeader();

      const params = {
        page: currentPage,
        limit: 20,
      };

      return await ApiService.query(`/admin/users/${entryData.value['id']}/auth-log`, { params })
        .then(response => {
          // console.log('> getUserAuthLogs >> response.data', response.data);
          isTableLoading.value = false;
          return response.data;
        })
          .catch(error => {
            isTableLoading.value = false;
            console.log('> getUserAuthLogs > error', error);
            return null;
          });
    };

    /**
     * Updates the table value.
     * @param {number} The current page number.
     */
    const updateTable = async (params) => {
      authLogsData.value = await getUserAuthLogs(params);
    };

    /**
     * Request for the subscriptions list.
     * @returns {array|null}.
     */
    const getSubscriptionTypes = async () => {
      ApiService.setHeader()

      const typesSet = await ApiService.get(`/admin/user-subscriptions/types`)
        .then(response => {
          return response.data;
        })
          .catch(error => {
            console.log('> getSubscriptionTypes > error', error);
            return null;
          });

      typesSet.forEach(
        item => {
          const mailingObject = {
            tab: 'mailing',
            as: 'base-single-checkbox',
            slug: `mailing-${item.id}`,
            value: false,
            label: item.name,
            text: null,
            available: true,
            highlighted: false,
            toTop: false,
          };
          // entryDataArray.value.push(mailingObject)
          entryDataFull.value[`mailing-${item.id}`] = mailingObject;
        }
      );
    };

    const removeUnnecessaryFieldsForCreate = () => {
      delete entryDataFull.value['registered_at'];
      delete entryDataFull.value['last_update'];
      delete entryDataFull.value['last_login'];
    };

    const makePasswordFieldsRequiredForCreate = () => {
      entryDataFull.value['password'].required = true;
      entryDataFull.value['password_confirm'].required = true;
    };

    /*********************
     * FORM END
     *********************/
    onMounted(async () => {
      await getSubscriptionTypes();
      await getRubrics();
      if (props.edit) {
        await getEntryData(propData);
        authLogsData.value = await getUserAuthLogs(1);
      } else {
        removeUnnecessaryFieldsForCreate();
        makePasswordFieldsRequiredForCreate();
        await getEntryData(entryDataFull.value);
      }
    });

    const phone = ref('124124');

    return {
      isEmptyObject,
      // FORM
      tabsSetConverted,
      activeTabName,
      entryData,
      entryDataArray,
      validationRules,
      // FORM ACTIONS
      saveForm,
      statusesSet,
      rubricsInitTree,
      addressSuggestions,
      authLogsData,
      convertedData,
      entryDataFull,
      updateTable,
      isTableLoading,
      phone,
    }
  },
})
