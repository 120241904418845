
import {
  ref,
  computed,
  defineComponent,
} from 'vue'
import * as Yup from "yup";

export default defineComponent({
  name: 'base-select',
  props: {
    modelValue: {
      type: String,
      required: false,
    },
    jsonValue: {
      type: Object,
      required: false,
    },
    id: {
      type: String,
      default: 'id-default',
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Введите значение',
      required: false,
    },
    clearable: {
      type: Boolean,
      default: true,
      required: false,
    },
    filterable: {
      type: Boolean,
      default: true,
      required: false,
    },
    size: {
      type: String,
      default: 'large',
      required: false,
    },
    options: {
      type: Array,
      default: () => [],
      required: false,
    },
    suggestions: {
      required: false
    },
    schema: {
      type: Object,
      default: null,
      required: false,
    },
    isValid: {
      type: Boolean,
      default: false,
      required: false,
    },
    errorMessage: {
      type: String,
      default: null,
      required: false,
    },
  },
  setup(props, context) {
    const errorMessage = ref(props.errorMessage);

    const fieldSchema = Yup.object().shape(props.schema);

    const onSelectAutocomplete = async (data) => {
      console.log('> onSelectAutocomplete >> data', data);
      context.emit('update:jsonValue', data);
      context.emit('update:modelValue', data.value);
    };

    const validateJson = (data) => {
      if (data && data.id) {
        return data;
      } else {
        return null;
      }
    };

    const inputModelValue = computed({
      get() {
        return props.modelValue;
      },
      async set(value) {
        // console.log('> props.jsonValue >>', props.jsonValue);
        // console.log('> txt value >>', value);

        if (!value) {
          context.emit('update:jsonValue', null);
        }

        let inputValue = value;

        await fieldSchema.validate(
          { field: value },
          { abortEarly: false },
        )
          .then(value => {
            inputValue = value.field;
            errorMessage.value = null;
          })
            .catch(err => {
              errorMessage.value = err.errors[err.errors.length - 1];
            });

        context.emit('update:isValid', errorMessage.value && errorMessage.value.length ? false : true);
        context.emit('update:errorMessage', errorMessage);
        context.emit('update:modelValue', inputValue);
      }
    });

    return {
      inputModelValue,
      onSelectAutocomplete,
    };
  },
})
