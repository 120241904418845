
import {
  ref,
  computed,
  onMounted,
  defineComponent,
} from 'vue'
import { mask } from 'vue-the-mask';
import * as Yup from "yup";

export default defineComponent({
  name: 'base-input-phone',
  directives: {
    mask,
  },
  props: {
    modelValue: {
      type: String,
    },
    id: {
      type: String,
      default: 'id-default',
      required: true,
    },
    showWordLimit: {
      type: Boolean,
      default: true,
      required: false,
    },
    minLength: {
      type: Number,
      required: false,
    },
    maxLength: {
      type: Number,
      required: false,
    },
    placeholder: {
      type: String,
      default: 'Введите значение',
      required: false,
    },
    clearable: {
      type: Boolean,
      default: true,
      required: false,
    },
    size: {
      type: String,
      default: 'large',
      required: false,
    },
    schema: {
      type: Object,
      default: null,
      required: false,
    },
    isValid: {
      type: Boolean,
      default: false,
      required: false,
    },
    errorMessage: {
      type: String,
      default: null,
      required: false,
    },
  },
  setup(props, context) {
    const errorMessage = ref(props.errorMessage);
    const phoneMasksArray = ref([]);
    const inputValueRaw = ref(null);
    const inputValueMasked = ref(null);

    const selectedMask = ref({
      label: '',
      value: '',
      mask: '',
    });

    const phoneMasksJson = {
      rus: {
        label: 'Россия',
        value: 'rus',
        mask: '+7 (###) ###-##-##',
      },
      other: {
        label: 'Другие страны',
        value: 'other',
        mask: '+# #################',
      },
    };

    /**
     * Detects the country mask depends on the phone number starting digits.
     * @param {string} The phone number.
     * @returns {string} The country slug.
     */
    const detectThePhoneMaskName = number => {
      if (number && (number.startsWith('+7') || number.startsWith('7'))) {
        return phoneMasksJson['rus'].value;
      } else {
        return phoneMasksJson['other'].value;
      }
    };

    const fieldSchema = Yup.object().shape(props.schema);

    /**
     * Converts a masks json object to an array.
     * @param {json} The JSON masks object.
     * @returns {array} The mask array.
     */
    const convertMasksArrayToJson = jsonObject => {
      const array = [];
      for (const prop in jsonObject) {
        array.push(jsonObject[prop]);
      }
      return array;
    };

    /**
     * Removes spaces and special characters.
     * @param {json} The JSON masks object.
     * @returns {array} The mask array.
     */
    const trimPhoneNumber = phoneString => {
      const finalNumber = phoneString.trim().replace(/[^A-Z0-9]+/ig,'');
      return finalNumber;
    };

    const inputModelValue = computed({
      get() {
        return inputValueMasked.value;
      },
      async set(value) {
        if (value) {
          inputValueRaw.value = trimPhoneNumber(value);
          inputValueMasked.value = value;
        }

        await fieldSchema.validate(
          { field: inputValueRaw.value },
          { abortEarly: false },
        )
          .then(value => {
            inputValueRaw.value = value.field;
            errorMessage.value = null;
          })
            .catch(err => {
              errorMessage.value = err.errors[err.errors.length - 1];
            });

        context.emit('update:isValid', errorMessage.value && errorMessage.value.length ? false : true);
        context.emit('update:errorMessage', errorMessage);

        return context.emit('update:modelValue', inputValueRaw.value);
      }
    });

    /**
     * Mounted hook.
     */
    onMounted(() => {
      inputValueMasked.value = props.modelValue;
      const phoneMaskName = detectThePhoneMaskName(props.modelValue);
      selectedMask.value = phoneMasksJson[phoneMaskName];
      phoneMasksArray.value = convertMasksArrayToJson(phoneMasksJson);
    });

    return {
      selectedMask,
      phoneMasksArray,
      inputModelValue,
    };
  },
})
