import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_input = _resolveComponent("el-input")!
  const _directive_mask = _resolveDirective("mask")!

  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.id,
    class: "phone-wrapper"
  }, [
    _createVNode(_component_el_select, {
      modelValue: _ctx.selectedMask,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedMask) = $event)),
      placeholder: "Выберите код страны"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.phoneMasksArray, (item, key) => {
          return (_openBlock(), _createBlock(_component_el_option, {
            key: key,
            label: item.label,
            value: item
          }, null, 8, ["label", "value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _withDirectives(_createVNode(_component_el_input, {
      type: "tel",
      class: _normalizeClass(["my-input w-100", {
        'el-input--invalid': !_ctx.isValid
      }]),
      maxlength: _ctx.maxLength,
      minlength: _ctx.minLength,
      clearable: _ctx.clearable,
      placeholder: _ctx.placeholder,
      modelValue: _ctx.inputModelValue,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.inputModelValue) = $event))
    }, null, 8, ["class", "maxlength", "minlength", "clearable", "placeholder", "modelValue"]), [
      [_directive_mask, _ctx.selectedMask.mask]
    ])
  ], 8, _hoisted_1))
}