import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, renderList as _renderList, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { class: "mb-0 pl-2" }
const _hoisted_2 = { class: "mb-0 pl-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_pre = _resolveComponent("base-pre")!
  const _component_el_result = _resolveComponent("el-result")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_base_table = _resolveComponent("base-table")!
  const _component_base_form_field = _resolveComponent("base-form-field")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_base_pre, {
      hide: "",
      title: "form entryDataFull",
      data: _ctx.entryDataFull
    }, null, 8, ["data"]),
    _createVNode(_component_base_pre, {
      hide: "",
      title: "form entryDataArray",
      data: _ctx.entryDataArray
    }, null, 8, ["data"]),
    _createVNode(_component_base_pre, {
      hide: "",
      title: "form authLogsData",
      data: _ctx.authLogsData
    }, null, 8, ["data"]),
    (_ctx.edit)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.entryData.status && _ctx.entryData.status.length)
            ? (_openBlock(), _createBlock(_component_el_result, {
                key: 0,
                icon: _ctx.statusesSet[_ctx.entryData.status].icon,
                title: _ctx.statusesSet[_ctx.entryData.status].title
              }, null, 8, ["icon", "title"]))
            : (_openBlock(), _createBlock(_component_el_result, {
                key: 1,
                icon: _ctx.statusesSet['unknown'].icon,
                title: _ctx.statusesSet['unknown'].title
              }, null, 8, ["icon", "title"]))
        ], 64))
      : _createCommentVNode("", true),
    _createElementVNode("form", null, [
      _createVNode(_component_el_tabs, {
        modelValue: _ctx.activeTabName,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.activeTabName) = $event)),
        class: "demo-tabs"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabsSetConverted, (tabsItem, tabsIndex) => {
            return (_openBlock(), _createBlock(_component_el_tab_pane, {
              key: tabsIndex,
              label: tabsItem.label,
              name: tabsItem.slug,
              class: "py-8"
            }, {
              default: _withCtx(() => [
                (tabsItem.slug === 'auth-log')
                  ? _withDirectives((_openBlock(), _createBlock(_component_base_table, {
                      key: 0,
                      itemsTotal: _ctx.authLogsData['total'],
                      pagesTotal: _ctx.authLogsData['pages'],
                      currentPage: _ctx.authLogsData['page'],
                      "onUpdate:currentPage": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.authLogsData['page']) = $event)),
                      filterShow: false,
                      paginationShow: true,
                      onUpdateTable: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateTable(_ctx.authLogsData['page'])))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_table, {
                          "empty-text": "Нет данных",
                          data: _ctx.authLogsData['items']
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_table_column, { label: "Дата" }, {
                              default: _withCtx((scope) => [
                                _createElementVNode("p", _hoisted_1, _toDisplayString(scope.row['created_at']), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_el_table_column, { label: "IP" }, {
                              default: _withCtx((scope) => [
                                _createElementVNode("p", _hoisted_2, _toDisplayString(scope.row['user_ip']), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["data"])
                      ]),
                      _: 1
                    }, 8, ["itemsTotal", "pagesTotal", "currentPage"])), [
                      [_directive_loading, _ctx.isTableLoading]
                    ])
                  : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.entryDataArray, (entryItem, entryIndex) => {
                      return (_openBlock(), _createElementBlock(_Fragment, { key: entryIndex }, [
                        (entryItem.tab === tabsItem.slug)
                          ? (_openBlock(), _createBlock(_component_base_form_field, {
                              key: 0,
                              id: entryItem.slug,
                              is: entryItem.as,
                              label: entryItem.label,
                              labelWidth: 220,
                              toTop: entryItem.toTop,
                              required: entryItem.required,
                              highlighted: entryItem.highlighted,
                              errorMessage: entryItem.errorMessage
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(), _createBlock(_resolveDynamicComponent(entryItem.as), {
                                  id: entryItem.slug,
                                  label: entryItem.label,
                                  modelValue: entryItem.value,
                                  "onUpdate:modelValue": ($event: any) => ((entryItem.value) = $event),
                                  jsonValue: entryItem.jsonValue,
                                  "onUpdate:jsonValue": ($event: any) => ((entryItem.jsonValue) = $event),
                                  isValid: entryItem.isValid,
                                  "onUpdate:isValid": ($event: any) => ((entryItem.isValid) = $event),
                                  errorMessage: entryItem.errorMessage,
                                  "onUpdate:errorMessage": ($event: any) => ((entryItem.errorMessage) = $event),
                                  maxLength: entryItem.maxLength,
                                  placeholder: entryItem.placeholder,
                                  showwordlimit: entryItem.showwordlimit,
                                  options: entryItem.options,
                                  suggestions: entryItem.suggestions,
                                  schema: entryItem.schema,
                                  type: entryItem.type
                                }, null, 8, ["id", "label", "modelValue", "onUpdate:modelValue", "jsonValue", "onUpdate:jsonValue", "isValid", "onUpdate:isValid", "errorMessage", "onUpdate:errorMessage", "maxLength", "placeholder", "showwordlimit", "options", "suggestions", "schema", "type"]))
                              ]),
                              _: 2
                            }, 1032, ["id", "is", "label", "toTop", "required", "highlighted", "errorMessage"]))
                          : _createCommentVNode("", true)
                      ], 64))
                    }), 128))
              ]),
              _: 2
            }, 1032, ["label", "name"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue"])
    ])
  ]))
}