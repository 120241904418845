
import {
  ref,
  computed,
  defineComponent,
} from 'vue';


export default defineComponent({
  name: 'users-filter',
  props: {
    itemsTotal: {
      default: 0,
      type: Number,
      required: true,
    },
    pagesTotal: {
      default: 0,
      type: Number,
      required: true,
    },
    currentPage: {
      default: 1,
      type: Number,
      required: true,
    },
    filterShow: {
      default: false,
      type: Boolean,
      required: true,
    },
    paginationShow: {
      default: false,
      type: Boolean,
      required: true,
    },
  },
  emits: ['update-table', 'update:currentPage'],
  setup(props, context) {
    const currentPageLocal = ref(props.currentPage);

    /**
     * Detect whether the table has a content.
     * Needed for the table pagionation.
     * @returns {boolean}.
     */
    const paginationShowLocal = computed(() => {
      return props.paginationShow && props.pagesTotal > 1 ? true : false;
    });

    const paginationClick = val => {
      currentPageLocal.value = val;
      console.log('> paginationClick >> value', currentPageLocal.value);
      context.emit('update:currentPage', currentPageLocal.value);
      context.emit('update-table');
    };

    return {
      paginationClick,
      currentPageLocal,
      paginationShowLocal,
    };
  },
})
