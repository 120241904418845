import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_autocomplete = _resolveComponent("el-autocomplete")!

  return (_openBlock(), _createBlock(_component_el_autocomplete, {
    id: _ctx.id,
    size: _ctx.size,
    label: "value",
    clearable: _ctx.clearable,
    filterable: _ctx.filterable,
    placeholder: _ctx.placeholder,
    "trigger-on-focus": false,
    "value-key": "value",
    class: "inline-input w-100",
    "aria-expanded": "true",
    modelValue: _ctx.inputModelValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputModelValue) = $event)),
    "fetch-suggestions": _ctx.suggestions,
    onSelect: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onSelectAutocomplete($event)))
  }, null, 8, ["id", "size", "clearable", "filterable", "placeholder", "modelValue", "fetch-suggestions"]))
}